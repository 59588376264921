
import React, { useState } from 'react'
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import MarkDown from "react-markdown"

//component
import Layout from "../components/layout";
import Seo from "../components/seo";
import TestimonialsVideo from "../components/Home/testimonials-video";
import TestimonialsText from "../components/Home/testimonials-text";
import LiveChat from "../components/Home/livechat";
import Counter from "../components/counter";
import Video from "../components/video"


const About = (props) => {
  const [isVideoOpen, setIsVideoOpen] = useState(false)
  const [openVideoIndex, setOpenVideoIndex] = useState(-1)
  const handleVideoIsOpen = (event, index) => {
    setOpenVideoIndex(index)
    setIsVideoOpen(true);
  }
  function handleVideoIsClose(isopenVideoState) {
    setIsVideoOpen(isopenVideoState);
  }
  const info = props.data.aboutInfo
  const countOnUs = props.data.countOnUs
  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang

  return (
    <>
      <Layout>
      <Seo
        title={SEOdata ? SEOdata.seo_title : "About-us"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={props.location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
      />

          {/* banner  section */}
          
        <section className="pt-1-25 lg:pb-54">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-47 md:w-4/5 lg:w-3/4 xl:w-3/5 mx-auto text-center relative">
              <h2 className="pb-3-1 lg:pb-3-2 text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-cyan text-center">
                {info.frontmatter.title}<br></br>
                <span className="text-term-primary">
                  {info.frontmatter.sub_title}
                </span>
              </h2>
              <MarkDown className="px-4 lg:px-0 xl:px-10 text-p4 lg:text-p2 font-worksans-normal text-term-primary text-center about-text" children={info.frontmatter.introduction} allowDangerousHtml />
            </div>

            {/* video section */}
            <Video isVideoOpen={isVideoOpen} openVideoIndex={openVideoIndex} onChange={handleVideoIsClose} videoSrcURL={info.frontmatter.video_section.video} elementIndex={1} />

            <div className="mx-auto xl:w-2/3 relative">
              <BgImage image={getImage(info.frontmatter.video_section.image)} className="about-video cursor-pointer" alt={`${info.frontmatter.video_section.alt} | DecodeUp About`}>
                <div className="block" role="presentation" onKeyDown={(event) => handleVideoIsOpen(event, 1)} onClick={(event) => handleVideoIsOpen(event, 1)}>
                  <span className="block absolute w-full h-full flex flex-col inset-0 md:items-center md:justify-center group items-center justify-center">
                    <svg className="w-52 md:w-65 lg:w-88 h-52 md:h-65 lg:h-88  cursor-pointer group-hover:opacity-75 transition-opacity duration-200 " id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98 98">
                      <title>Group 3</title>
                      <circle fill="#fff" cx="49" cy="49" r="49" />
                      <path fill="#c4c4c4" d="M62.36,48.44,43.15,59.53V37.35Z" />
                    </svg>
                    <span className="pt-3 font-poppings-bold text-rh6 lg:text-h6 text-white text-center">
                      {info.frontmatter.video_section.text_video}
                    </span>
                  </span>
                </div>
              </BgImage>
            </div>

            {/* description */}

            <div className="mx-auto xl:w-2/3 relative">
              <div className="px-0-3 lg:px-0 pt-35 lg:pt-60 about-video-contain">
                <div className="text-p4 lg:text-p2 font-worksans-normal text-term-primary text-left md:text-center" dangerouslySetInnerHTML={{ __html: info.html }} />
              </div>
            </div>
          </div>
        </section>

        {/* vision section */}

        <section className="pt-50 lg:pt-44">
          <div className="container mx-auto">
            <div className="px-1-1 mx-auto vision-contain">
              <div className="py-61 pl-6 pr-4-2 md:px-60 lg:pt-16 lg:pb-69 bg-cyan text-left sm:text-center">
                <p className="text-center pb-3 lg:pb-3-2 font-poppings-bold text-rh6 lg:text-h6 text-white opacity-60 uppercase">
                  Vision
                </p>
                <h3 className="text-center font-worksans-bold text-rp1 lg:text-h3 text-white">
                  {info.frontmatter.vision}
                </h3>
              </div>
            </div>
          </div>
        </section>

        {/* our mision */}

        <section className="pt-50 lg:pt-1-07">
          <div className="container mx-auto">
            <div className=" mx-auto block sm:flex flex-wrap justify-between sm:w-4/5 our-mission-contain">
              <div className="pb-4 lg:pb-0 w-full sm:w-1/4">
                <h3 className="text-center lg:text-left font-poppings-bold text-rp1 lg:text-h3 text-term-primary">
                  Our <br className="hidden sm:block"></br> Mission
                </h3>
              </div>
              <div className="text-center lg:text-left capitalize lg:pl-5-2 w-full sm:w-3/4 text-p4 lg:text-p2 font-worksans-normal text-term-primary">
                <MarkDown children={info.frontmatter.our_mision} allowDangerousHtml />
              </div>
            </div>
          </div>
        </section>

        {/* core value */}

        <section className="pt-50 lg:pt-86">
          <div className="container mx-auto">
            <div className="lg:px-1-1 mx-auto flex flex-wrap justify-between core-contain lg:w-3/4 xl:w-2/3">
              <div className="pb-5 lg:pb-6-4 w-full text-left sm:text-center">
                <h3 className="text-center font-poppings-bold text-rp1 lg:text-h3 text-term-primary">
                  Core Value
                </h3>
              </div>
              <div className="lg:px-2-2 w-full flex flex-wrap justify-between ms:justify-center   ">
                {info.frontmatter.core_values.group.map((value, v) =>
                  <div key={v} className="w-99  md:w-1-40 mb-6-3 mx-6 sm:mb-0 sm:mx-6-6 lg:mx-10 text-center">
                    <div className="mx-auto mb-3-1 w-75 h-75 text-center">
                      <img
                        src={value.svg.publicURL}
                        alt={value.alt}
                      ></img>
                    </div>
                    <p className="text-term-primary font-poppings-bold text-p4 lg:text-p2">
                      {value.name}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* count on us section */}

        <section className="mt-50 py-35 lg:mt-20 xl:mt-1-21 lg:pt-71 lg:pb-1-11 bg-background-senary">
          <div className="container mx-auto">
            <div className="lg:px-1-1 mx-auto flex flex-wrap justify-between count-contain lg:w-4/5 xl:w-2/3">
              <div className="pb-4-1 sm:pb-6 lg:pb-41 text-left sm:text-center mx-auto">
                <h3 className="pb-1-2 lg:pb-2-3 font-poppings-bold text-rp1 lg:text-h3 text-term-primary">
                  You Can Count On Us
                </h3>
                <MarkDown className="md:px-10 lg:px-5 text-p4 lg:text-p2 font-worksans-normal text-term-primary count-text" children={countOnUs.frontmatter.achievements.description} allowDangerousHtml />
              </div>

              {/* count down component */}
              <Counter counterValue={countOnUs.frontmatter.achievements.values.group} />

            </div>
          </div>
        </section>

        {/* client video testimonials carousel section */}
        <section>
          {/* <div className="container mx-auto bg-alumn-500"> */}
          <div className="container mx-auto">
            <TestimonialsVideo />
          </div>
        </section>
        {/* client text testimonials carousel section */}
        <section>
          <div className="container mx-auto">
            <div className="pt-5 pb-53 pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 lg:pt-0 lg:pb-1-40 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* schedule a free consultation chat section */}
        <LiveChat />

      </Layout>
    </>
  )
}

export default About;

export const pageQuery = graphql`
  query {
    aboutInfo: markdownRemark(fileAbsolutePath: {regex: "/about-us.md/"}) {
      frontmatter {
        title
        sub_title
        introduction
        video_section {
          alt
          text_video
          image {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
          video
        }
        vision
        our_mision
        core_values {
          group {
            alt
            name
            svg {
              publicURL
            }
          }
        }
      }
      html
    }
    countOnUs: markdownRemark(fileAbsolutePath: {regex: "/achievements-count-on-us.md/"}) {
      frontmatter {
        achievements {
          description
          values {
            group {
              count
              title
              svg {
                publicURL
              }
            }
          }
        }
      }
    }
    pageData: allMarkdownRemark(
      filter: { fileAbsolutePath: {regex: "/about-us.md/"} }
    ) {
      edges {
        node {
          frontmatter {
            seo_metadata {
              seo_title
              seo_keyword
              seo_description
              image_format
              feature_image_height
              img_width
              facebook_url
              twitter_page_username
              twitter_image_alt_tag
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              page_publish_date
              page_last_modified_time
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
            Schema {
              sitelinks_schema
              breadcrumb_schema
              logo_schema
              organization_schema
              aggregate_rating_schema
              local_business_schema
              site_navigation_schema
              speakable_specification_schema
            }
            Hreflang {
              hreflang_1
              hreflang_2
              hreflang_3
              hreflang_4
            }
          }
        }
      }
    }
  }
`;